import React from 'react';
import logo3 from '../../../assets/image/logo/logo@3x.png';
import logo2 from '../../../assets/image/logo/logo@2x.png';
import logo1 from '../../../assets/image/logo/logo@1x.png';
import MainMenu from "./menu";
import {ClockIcon, EmailIcon, LoginIcon, PhoneIcon, PinIcon, UnlockIcon} from "../icons/icons";
import LogoTaglineBlock from "../block/logo-tagline-block";

const Header = () => {
    return (
        <header>
            <div className="container">
                <div className="header">
                    <LogoTaglineBlock logo1={logo1} logo2={logo2} logo3={logo3}/>
                    <div className="info">
                        <div className="wrp">
                            <div className="block">
                                <PhoneIcon/>
                                <a href="tel:+73472008283">+7 (347) 200 82 83</a>
                            </div>
                            <div className="block">
                                <ClockIcon/>
                                <span>Пн-Пт: 9:00-18:00</span>
                            </div>
                        </div>
                        <div className="wrp">
                            <div className="block">
                                <EmailIcon/>
                                <a href="mailto:support@prottego.ru">support@prottego.ru</a>
                            </div>
                            <div className="block">
                                <PinIcon/>
                                <span>г. Бирск, ул. Коммунистическая, 97В </span>
                            </div>
                        </div>

                    </div>
                    <div className="info auth">
                        <div className="wrp">
                            <div className="block">
                                <UnlockIcon/>
                                <a href="http://office.telemetry.prottego.ru">Войти</a>
                            </div>
                            {/*<div className="block">*/}
                            {/*    <LoginIcon/>*/}
                            {/*    <a href="#">Регистрация</a>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>

            </div>
            <div className="header-menu">
                <div className="container">
                    <MainMenu/>
                </div>
            </div>
        </header>
    )
}

export default Header;