import {Map, Placemark, YMaps} from 'react-yandex-maps';
import React from 'react';

const ContactMap = () => (
    <YMaps>
        <Map defaultState={{center: [55.411708, 55.554767], zoom: 17}} modules={['geoObject.addon.balloon']} width={"100%"} height={"400px"}>
            <Placemark defaultGeometry={[55.411708, 55.554767]} defaultProperties={{
                iconCaption: "Безопасная школа",
                balloonContent: 'Россия, г. Бирск, ул. Коммунистическая, 97В',
            }}/>
        </Map>
    </YMaps>
)

export default ContactMap;