import React from 'react';
import PropTypes from 'prop-types';

const ProblemBlock = (props) =>
    <div className="problem-block">
        {props.icon}
        <div>
            {props.text}
        </div>
    </div>

PropTypes.propTypes = {
    icon: PropTypes.element.isRequired,
    text: PropTypes.string.isRequired
}

export default ProblemBlock;