import React from 'react';
import PropTypes from 'prop-types';
import IconTextBlock from "./icon-text-block";


const AdvantageBlock = (props) =>
    <div className='advantage-text-block'>
        {props.icon}
        <div className="text">
            <h4>{props.title}</h4>
            <span>{props.text}</span>
        </div>
    </div>

IconTextBlock.propTypes = {
    icon: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

export default AdvantageBlock;