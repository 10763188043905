import React, {Component} from 'react';
import PageTemplate from "./base/base";
import notebook1 from '../../assets/image/solution/notebook@1x.png'
import notebook2 from '../../assets/image/solution/notebook@2x.png'
import Popup from "../components/popup";
import AdvantageBlock from "../components/block/advantage-block";
import {
    AppointmentIcon,
    BellIcon,
    BinocularsIcon,
    ChipIcon,
    DeepIcon,
    EmployeeIcon,
    LampIcon,
    LeakIcon,
    LikeIcon,
    MagnifierIcon,
    MoneyIcon,
    PercentageIcon,
    ProjectIcon,
    ToolsIcon
} from "../components/icons/icons";
import counter1 from '../../assets/image/solution/counter@1x.png'
import counter2 from '../../assets/image/solution/counter@2x.png'
import ChartBlock from "../components/block/chart-block";
import chart11 from '../../assets/image/solution/chart1@1x.jpg';
import chart12 from '../../assets/image/solution/chart1@2x.jpg';
import chart21 from '../../assets/image/solution/chart2@1x.jpg';
import chart22 from '../../assets/image/solution/chart2@2x.jpg';
import chart31 from '../../assets/image/solution/chart3@1x.jpg';
import chart32 from '../../assets/image/solution/chart3@2x.jpg';
import chart41 from '../../assets/image/solution/chart4@1x.jpg';
import chart42 from '../../assets/image/solution/chart4@2x.jpg';
import ArrowBlock from "../components/block/arrow-block";
import ProblemBlock from "../components/block/problems-block";
import FutureBlock from "../components/block/future-block";
import monitor1 from "../../assets/image/solution/monitor@1x.png";
import monitor2 from "../../assets/image/solution/monitor@2x.png";
import ConfidenceBlock from "../components/block/confidence-block";
import {Helmet} from "react-helmet";

class Solution extends Component {
    constructor() {
        super();
        this.state = {
            showPopup: false
        }
        this.handleClickPopup = this.handleClickPopup.bind(this)
    }

    handleClickPopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    render() {
        return (
            <PageTemplate>
                <Helmet>
                    <title>Решения | Автоматизация учёта тепловой энергии </title>
                    <meta name="description" content="⚑ Автоматизированная система дистанционного сбора
                    данных с приборов для управляющих компаний и ЖКХ. Сократите затраты на 30% за счёт автоматизации сбора данных и полного контроля за расходом тепла в доме" />
                </Helmet>
                <div className="solution-block-main">
                    <div className="container">
                        <div>
                            <img src={notebook1} srcSet={`${notebook1} 1x, ${notebook2} 2x`}
                                 alt="Система дистанционного учёта тепловой энергии"/>
                        </div>

                        <div className="text">
                            <h1>Беспроводной учёт тепловой энергии</h1>
                            <div>Автоматизированная система дистанционного сбора данных
                                с приборов для управляющих компаний и ЖКХ. Сократите затраты
                                на 30% за счёт автоматизации сбора данных и полного контроля
                                за расходом тепла в доме
                            </div>
                            <button onClick={this.handleClickPopup} className="btn btn-blue">Получить консультацию</button>
                        </div>
                    </div>
                </div>
                <div className="solution-block-advantage container">
                    <AdvantageBlock icon={<PercentageIcon/>} title="Экономия до 30%" text="Снижение издержек за счёт
                    исключения обходчиков и как следствие-экономия Вашего бюджета"/>
                    <AdvantageBlock icon={<ChipIcon/>} title="Упрощение работы" text="Автоматизация сбора данных
                    существенно сократит временные издержки"/>
                    <AdvantageBlock icon={<BellIcon/>} title="Оповещения" text="Открытая информация по состоянию
                    приборов в вашем личном кабинете (протечки, ошибки)"/>
                    <AdvantageBlock icon={<DeepIcon/>} title="Без замены счетчиков" text="Наше решение позволяет
                    снимать показания со старых импульсных счётчиков дистанционно!"/>
                </div>
                <div className="solution-block-work">
                    <div className="container">
                        <div className="text">
                            <h2>Как работает дистанционное снятие показаний с приборов учёта?</h2>
                            <div>Система состоит из конвертеров с модулем LoRaWAN, которые устанавливаются к
                                общедомовому прибору для передачи данных. Данные передаются по защищённому радиоканалу
                                на базовую станцию, затем в личный кабинет управляющей компании, где можно детально
                                наблюдать за показаниями и ошибками приборов. Внедрение LoRaWAN- самое
                                эконономически-выгодное решение для автоматизации энергоучёта.
                            </div>
                        </div>
                        <img src={counter1} srcSet={`${counter1} 1x, ${counter2} 2x`} alt="Дистанционное снятие показаний
                        со счётчиков"/>
                    </div>
                </div>
                <div className="solution-block-chart container">
                    <ChartBlock image={[chart11, chart12]} text="Счётчик отправляет данные" title="Датчики контроля"/>
                    <ChartBlock image={[chart21, chart22]} text="Базовая станция получает данные"
                                title="Базовая станция"/>
                    <ChartBlock image={[chart31, chart32]} text="Хранение и обработка данных" title="Сервер"/>
                    <ChartBlock image={[chart41, chart42]} text="Просмотр онлайн показаний" title="Личный кабинет"/>
                </div>
                <div className="solution-block-problems">
                    <div className="container">
                        <h2>С какими проблемами сталкивается управляющая компания при традиционном сборе показаний?</h2>
                        <div className="problem-block-group">
                            <ProblemBlock icon={<MoneyIcon/>} text="Высокий показатель общедомовых нужд"/>
                            <ProblemBlock icon={<AppointmentIcon/>} text="Несвоевременная оплата счетов жильцами"/>
                            <ProblemBlock icon={<ToolsIcon/>} text="Нет информации о неисправности прибора"/>
                            <ProblemBlock icon={<MagnifierIcon/>} text="Необходимость ежемесячного обхода счётчиков"/>
                        </div>

                        <ArrowBlock/>
                    </div>
                </div>
                <div className="solution-block-future">
                    <div className="container">
                        <h2>Что ждёт управляющую компанию после внедрения<br/>"Проттего Телеметрия"?</h2>
                        <div className="future-block-group">
                            <FutureBlock icon={<BinocularsIcon/>} title="Контроль баланса энергопотребления"/>
                            <FutureBlock icon={<LeakIcon/>} title="Контроль очагов потери ресурсов"/>
                            <FutureBlock icon={<ChipIcon/>} title="Исключение человеческого фактора"/>
                        </div>
                        <div className="grey-block">
                            <h4>Снижайте высокие расходы на общедомовые нужды до нормальных значений</h4>
                            <span>Благодарая системному учёту ресурсов в каждой квартире, а так же предотвратите хищение энергоресурсов жильцами</span>
                        </div>
                    </div>
                </div>
                <div className="solution-block-office">
                    <div className="container">
                        <div className="text">
                            <h2>Личный кабинет администратора "Проттего телеметрия"</h2>
                            <ul>
                                <li>Просмотр показаний приборов учёта</li>
                                <li>Групировка по домам, подъездам, перегревам/недогревам</li>
                                <li>Оповещение о перегреве/недогреве</li>
                                <li>Выгрузка отчётов в Excel/1C</li>
                            </ul>
                            <a href="#" className="btn btn-white">Открыть демо-версию</a>
                        </div>
                        <img src={monitor1} srcSet={`${monitor1} 1x, ${monitor2} 2x`} alt="Изображение монитора"/>
                    </div>
                </div>
                <div className="solution-block-confidence">
                    <div className="container">
                        <h2>Почему стоит доверить работу нам?</h2>
                        <div className="confidence-block-group">
                            <ConfidenceBlock icon={<ProjectIcon/>}
                                             text="Выполняем работы под ключ включая проектирование, монтаж, пусконаладочные работы"/>
                            <ConfidenceBlock icon={<EmployeeIcon/>}
                                             text="Поддерживаем клиентов на всех этапах - от проекта до спорных ситуаций с ресурсной организацией"/>
                        </div>
                        <div className="confidence-block-group">
                            <ConfidenceBlock icon={<LampIcon/>}
                                             text="Рынок предлагает управляющим компаниям заменить старые приборы учёта на новые умные счётчики. <b>Проттего Телеметрия предлагает</b> просто дооборудовать Ваши счетчики,
                                             а наше решение спокойно передаст данные в Ваш личный кабинет"/>
                            <ConfidenceBlock icon={<LikeIcon/>}
                                             text="Мы используем надежное и зарекомендованное оборудование ВЕГА. Его выбирают такие организации как Ростелеком, МосОблГаз, Яндекс.Драйв, Платон, Continental, Дом.ru и многие другие"/>
                        </div>
                    </div>
                </div>
                {this.state.showPopup ?
                    <Popup closePopup={this.handleClickPopup}/>
                    : null
                }
            </PageTemplate>
        )
    }
}


export default Solution;