import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";


const LogoTaglineBlock = (props) =>
    <div className="logo-horizontal">
        <NavLink className="logo" to="/">
                <img src={props.logo1} srcSet={`${props.logo1} 1x, ${props.logo2} 2x, ${props.logo3} 3x`}
                     alt="Логотип компании"/>
                <div className="logo-name">
                    <div className="title">prottego</div>
                    <div className="subtitle">телеметрия</div>
            </div>
        </NavLink>

        <div className="tagline">
            Готовые решения <br/> удаленного сбора данных
        </div>
    </div>


LogoTaglineBlock.propTypes = {
    logo1: PropTypes.string.isRequired,
    logo2: PropTypes.string.isRequired,
    logo3: PropTypes.string.isRequired,
}

export default LogoTaglineBlock