import React, {Component} from 'react';
import PageTemplate from "./base/base";
import fetch from "isomorphic-fetch";
import NewsBlock from "../components/block/news-block";
import ReactPaginate from 'react-paginate';
import {Helmet} from "react-helmet";

class NewsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            count: 0,
            page: 1,
        };
        this.handlePageClick = this.handlePageClick.bind(this)
    }

    loadNewsFromServer() {
        fetch(`${process.env.API_URL}/api/news/?page=${this.state.page}`)
            .then(response => response.json())
            .then(news => this.setState({news: news.results, count: Math.ceil(news.count / 6)}))
    }

    componentDidMount() {
        this.loadNewsFromServer()
    }

    handlePageClick(data) {
        let selected = data.selected;
        let page = selected + 1;
        this.setState({page: page}, () => {
            this.loadNewsFromServer();
        });
    };

    render() {
        const {news} = this.state
        return (
            <PageTemplate>
                <Helmet>
                    <title>Новости | Prottego Телеметрия</title>
                </Helmet>
                <div className="news-block-main">
                    <div className="container">
                        <h2>Новости</h2>
                        {news.length ?
                            <div>
                                <div className="news-block-group">
                                    {news.map(
                                        (item) => <NewsBlock key={item.id.toString()} image={item.image}
                                                             title={item.title}
                                                             short_text={item.short_text}
                                                             url={item.url}/>)}
                                </div>
                                <div className="clear"/>
                                <ReactPaginate
                                    pageCount={this.state.count}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                />
                            </div>
                            : <div>Данный раздел находится в разработке</div>}
                    </div>
                </div>
            </PageTemplate>
        )
    }
}

export default NewsList;