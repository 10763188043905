import React from 'react';
import PropTypes from 'prop-types';


const FutureBlock = (props) =>
    <div className='future-block'>
        {props.icon}
        <h4>{props.title}</h4>
    </div>

FutureBlock.propTypes = {
    icon: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
}

export default FutureBlock;