import React from 'react';
import PropTypes from 'prop-types';


const TechnologyAdvantageBlock = (props) =>
    <div className='technology-advantage-block'>
        {props.icon}
        <span>{props.text}</span>
    </div>

TechnologyAdvantageBlock.propTypes = {
    icon: PropTypes.element.isRequired,
    text: PropTypes.string.isRequired,
}

export default TechnologyAdvantageBlock;