import React from 'react';
import PropTypes from 'prop-types';


const ChartBlock = (props) =>
    <div className='chart-block'>
        <div className="text">{props.text}</div>
        <img src={props.image[0]} srcSet={`${props.image[0]} 1x, ${props.image[1]} 2x`} alt={props.title}/>
        <div className="title">{props.title}</div>
    </div>

ChartBlock.propTypes = {
    image: PropTypes.array.isRequired,

    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

export default ChartBlock;