import React from 'react';
import PropTypes from 'prop-types';

const IconTextBlock = (props) =>
    <div className="icon-text-block">
        {props.icon}
        <div className="text">
            <h3>{props.title}</h3>
            <span>{props.text}</span>
        </div>
    </div>


IconTextBlock.propTypes = {
    icon: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

export default IconTextBlock