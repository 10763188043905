import React, {Component} from 'react';
import PageTemplate from "./base/base";
import Popup from "../components/popup";
import technology1 from "../../assets/image/technology/technology@1x.png";
import technology2 from "../../assets/image/technology/technology@2x.png";
import TechnologyAdvantageBlock from "../components/block/technology-advantage-block";
import {
    BatteryIcon,
    BigDataIcon,
    LockIcon,
    PlaceIcon,
    PointerIcon,
    RadioIcon,
    ReductionIcon,
    SatelliteDishIcon,
    WifiIcon
} from "../components/icons/icons";
import OpportunitiesBlock from "../components/block/opportunities-block";
import utilities1x from "../../assets/image/technology/utilities@1x.jpg";
import utilities2x from "../../assets/image/technology/utilities@2x.jpg";
import energetics1x from "../../assets/image/technology/energetics@1x.jpg";
import energetics2x from "../../assets/image/technology/energetics@2x.jpg";
import agriculture1x from "../../assets/image/technology/agriculture@1x.jpg";
import agriculture2x from "../../assets/image/technology/agriculture@2x.jpg";
import smart_home1x from "../../assets/image/technology/smart_home@1x.jpg";
import smart_home2x from "../../assets/image/technology/smart_home@2x.jpg";
import logic1x from "../../assets/image/technology/logic@1x.jpg";
import logic2x from "../../assets/image/technology/logic@2x.jpg";
import production1x from "../../assets/image/technology/production@1x.jpg";
import production2x from "../../assets/image/technology/production@2x.jpg";
import {Helmet} from "react-helmet";


class Technology extends Component {
    constructor() {
        super();
        this.state = {
            showPopup: false
        }
        this.handleClickPopup = this.handleClickPopup.bind(this)
    }

    handleClickPopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    render() {
        return (
            <PageTemplate>
                <Helmet>
                    <title>Технология LoraWAN | Prottego Телеметрия</title>
                    <meta name="description" content="⚑ На базе уникальных возможностей сети телеметрии мы разработали
                    готовое решение для ЖКХ, работающее по технологии LoRaWAN позволяющее дистанционно снимать показания с приборов учёта" />
                </Helmet>
                <div className="technology-block-main">
                    <div className="container">
                        <div className="text">
                            <h1>Технологии для интернета вещей</h1>
                            <div>Новая беспроводная технология дальнего радиуса действия
                                и малого потребления электроэнергии
                            </div>
                            <button onClick={this.handleClickPopup} className="btn btn-blue">Запросить расчет</button>
                        </div>
                        <div>
                            <img src={technology1} srcSet={`${technology1} 1x, ${technology2} 2x`}
                                 alt="Сервис дистанционного снятия показаний с приборов учёта для ЖКХ"/>
                        </div>
                    </div>
                </div>
                <div className="technology-block-lorawan">
                    <div className="container">
                        <div>
                            Технология <span className="blue bold">LoRaWAN</span>- это технический стандарт
                            разработанный и поддерживаемый альянсом LoRa. Данные
                            передаются по защищённому радиоканалу на базовую станцию, а в личном кабинете управляющей
                            компании можно детально наблюдать за показаниями и ошибками приборов. Внедрение
                            LoRAWAN-
                            самое эконономически-выгодное решение для автоматизации <span
                            className="blue bold">энергоучёта.</span>
                        </div>
                        <div className="technology-advantage-block-group">
                            <TechnologyAdvantageBlock icon={<RadioIcon/>} text="Высокая дальность связи"/>
                            <TechnologyAdvantageBlock icon={<BatteryIcon/>}
                                                      text="Низкое энергопотребление (10 лет на одной батареи)"/>
                            <TechnologyAdvantageBlock icon={<LockIcon/>} text="Защищенность данных"/>
                        </div>
                        <div className="technology-advantage-block-group">
                            <TechnologyAdvantageBlock icon={<PointerIcon/>}
                                                      text="Масштабируемость. Одна базовая станция позволяет обслуживать до 5 тыс. оконечных устройств"/>
                            <TechnologyAdvantageBlock icon={<BigDataIcon/>}
                                                      text="Быстрое развертывание сетей и совместимость устройств"/>
                            <TechnologyAdvantageBlock icon={<SatelliteDishIcon/>}
                                                      text="Высокая пропускная способность одной станции"/>
                        </div>
                        <div className="technology-advantage-block-group">
                            <TechnologyAdvantageBlock icon={<PlaceIcon/>}
                                                      text="Возможность определить координаты устройств без GPS"/>
                            <TechnologyAdvantageBlock icon={<ReductionIcon/>}
                                                      text="Снижение на эксплуатацию оконечных устройств"/>
                            <TechnologyAdvantageBlock icon={<WifiIcon/>} text="Высокая проникаемость связи"/>
                        </div>
                    </div>
                </div>
                <div className="technology-block-opportunities">
                    <div className="container">
                        <h2>Возможности использованивая <span className="blue">LoRaWAN</span> безграничны</h2>

                        <p>
                            На базе уникальных возможностей сети телеметрии мы разрабатываем готовые комплексные решения
                            для
                            различных отраслей, имеющие неоспоримые конкурентные преимущества по надежности, стоимости,
                            потребительским и техническим характеристикам.
                        </p>
                        <div className="opportunities-block-group">
                            <OpportunitiesBlock image1={utilities1x} image2={utilities2x} title="ЖКХ"/>
                            <OpportunitiesBlock image1={energetics1x} image2={energetics2x} title="Энергетика"/>
                            <OpportunitiesBlock image1={agriculture1x} image2={agriculture2x} title="Сельское хозяйство"/>
                        </div>
                        <div className="opportunities-block-group">
                            <OpportunitiesBlock image1={smart_home1x} image2={smart_home2x} title="Умный дом"/>
                            <OpportunitiesBlock image1={logic1x} image2={logic2x} title="Логистика"/>
                            <OpportunitiesBlock image1={production1x} image2={production2x} title="Производство"/>
                        </div>
                    </div>
                </div>
                {this.state.showPopup ?
                    <Popup closePopup={this.handleClickPopup}/>
                    : null
                }
            </PageTemplate>
        )
    }

}

export default Technology;