import React, {Component} from 'react';
import PageTemplate from "./base/base";
import fetch from "isomorphic-fetch";
import {Helmet} from "react-helmet";
import NewsBlock from "../components/block/news-block";

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            main_news: '',
            news: [],
        }
    }

    loadNewsIdFromServer() {
        fetch(`${process.env.API_URL}/api/news/${this.props.match.params.id}`)
            .then(response => response.json())
            .then(main_news => this.setState({'main_news': main_news}))
        fetch(`${process.env.API_URL}/api/news/?page_size=4`)
            .then(response => response.json())
            .then(news => {
                news.results.map((item, index) => {
                    if (item.url === this.props.match.params.id) {
                        news.results.splice(index, 1)
                    }
                })
                if (news.results.length > 3) {
                    news.results.splice(3, 1)
                }
                this.setState({'news': news.results})
            })
    }

    componentDidMount() {
        this.loadNewsIdFromServer()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.loadNewsIdFromServer()
            window.scrollTo(0, 0)
        }
    }

    render() {
        const {main_news, news} = this.state

        return (
            <PageTemplate>
                <Helmet>
                    <title>{`${main_news.title} | Prottego Телеметрия`}</title>
                    <meta name="description" content={main_news.description}/>
                </Helmet>
                <div className="detail-news-block-main container">
                    <h2>{main_news.title}
                    </h2>
                    <div dangerouslySetInnerHTML={{__html: main_news.full_text}}/>
                </div>
                <hr/>
                <div className="news-block-main">
                    <div className="container">
                        <div className="news-block-group">
                            {news.map(
                                (item) => <NewsBlock key={item.id.toString()} image={item.image}
                                                     title={item.title}
                                                     short_text={item.short_text}
                                                     url={item.url}/>)}
                        </div>
                    </div>
                </div>

            </PageTemplate>
        )

    }
}

export default News;