import React from 'react';
import LogoTaglineBlock from "../block/logo-tagline-block";
import logo1 from "../../../assets/image/logo/logo-grey@1x.png";
import logo2 from "../../../assets/image/logo/logo-grey@2x.png";
import logo3 from "../../../assets/image/logo/logo-grey@3x.png";
import MainMenu from "../header/menu";

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <LogoTaglineBlock logo1={logo1} logo2={logo2} logo3={logo3}/>
                <div className="footer-menu">
                    <MainMenu/>
                </div>
            </div>
        </footer>
    )
}

export default Footer;