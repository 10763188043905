import React from 'react';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

class ScrollToTopOnMount extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return null;
    }
}

const PageTemplate = ({children}) =>
    <React.Fragment>
        <ScrollToTopOnMount />
        <Header/>
        <main>
            {children}
        </main>
        <Footer/>
    </React.Fragment>

export default PageTemplate;