import React, {Component} from 'react';
import {CloseIcon} from "./icons/icons";
import FeedbackForm from "./forms/feedback";


class Popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thanks: false
        }
        this.handleSubmitForm = this.handleSubmitForm.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (document.activeElement !== document.body) {
                document.activeElement.blur()
            }
            this.props.closePopup()
        }
    }

    handleSubmitForm() {
        this.setState({
            thanks: !this.state.thanks
        });
    }

    render() {
        return (
            <div className='popup'>
                <div className='popup_inner' ref={this.setWrapperRef}>
                    <button className="close" type="button" onClick={this.props.closePopup}>
                        <CloseIcon/>
                    </button>
                    {this.state.thanks ?
                        <div>
                            <h2>Ваша заявка отправлена</h2>
                            <div className="text">Мы обязательно свяжемся с вами</div>
                        </div>
                        :
                        <div>
                            <h2>Оставьте заявку и специалист свяжется с вами</h2>
                            <div className="text">А чтобы не ждать ответа, звоните по телефону <a href="tel:+73472008283">+7 (347) 200 82 83</a></div>
                            <FeedbackForm SubmitForm={this.handleSubmitForm}/>
                        </div>
                    }

                </div>
            </div>
        )
    }
}

export default Popup;