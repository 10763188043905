import React from 'react';
import PageTemplate from "./base/base";
import NotFoundImage from '../../assets/image/404.jpg';
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";

export const NotFound = () =>
    <PageTemplate>
        <Helmet>
            <title>Страница не найдена | Prottego Телеметрия</title>
        </Helmet>
        <div className="container">
            <div className="error-code">
                <img src={NotFoundImage} alt="Страница не найдена"/>
            </div>
            <div className="error-text">
                <div>Данной странцы не существует</div>
                <br/>
                <NavLink exact to="/">
                    Вернуться на главную
                </NavLink>
            </div>

        </div>


    </PageTemplate>

export default NotFound;