import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

const NewsBlock = props =>
    <Link to={`/news/${props.url}`} className="news-block">
        <img src={props.image} alt={props.title}/>
        <div className="body">
            <h4>{props.title}</h4>
            <div>{props.short_text}</div>
            <div className="details">Узнать подробнее</div>
        </div>
    </Link>

NewsBlock.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    short_text: PropTypes.string

}

export default NewsBlock;
