import React, {Component} from 'react';
import PageTemplate from "./base/base";
import phone1 from '../../assets/image/main/phone@1x.png'
import phone2 from '../../assets/image/main/phone@2x.png'
import {NavLink} from "react-router-dom";
import {CloudIcon, DeepIcon, Factory, Faucet, Skyscrapper, ToolsIcon} from "../components/icons/icons";
import counter1 from '../../assets/image/main/counter@1x.png';
import counter2 from '../../assets/image/main/counter@2x.png'
import monitor1 from '../../assets/image/main/monitor@1x.png';
import monitor2 from '../../assets/image/main/monitor@2x.png';
import presentation2 from '../../assets/image/main/presentation@2x.jpg'
import Popup from "../components/popup";

import IconTextBlock from "../components/block/icon-text-block";
import ArrowBlock from "../components/block/arrow-block";
import {Helmet} from "react-helmet";

class Home extends Component {
    constructor() {
        super();
        this.state = {
            block1: true,
            block2: false,
            block3: false,
            showPopup: false
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleClickPopup = this.handleClickPopup.bind(this)
    }

    handleClick(index, e) {
        if (!this.state[`block${index}`]) {
            this.setState({block1: false, block2: false, block3: false})
            this.setState({[`block${index}`]: !this.state[`block${index}`]});
        }
    }

    handleClickPopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    render() {
        return (
            <PageTemplate>
                <Helmet>
                    <title>Главная | Система удалённого сбора показаний с приборов учёта ЖКХ </title>
                    <meta name="description" content="➤ «Проттего» является разработчиком программного обеспечения
                    для удалённого снятия показаний с теплоприбора, интегратором беспроводных технологий LoRaWAN в приборы учёта ЖКХ" />
                </Helmet>
                <div className="home-block-main">
                    <div className="container">
                        <div className="text">
                            <h1>Комплексное решение удалённого учёта энергоресурсов для управляющих компаний</h1>
                            <span>Сократите затраты за счет автоматизации сбора данных и системы контроля за потерями энергоресурсов.</span>
                            <div className="btn-group">
                                <NavLink to="/solution" className="btn btn-primary">
                                    Узнать подробнее
                                </NavLink>
                                <button onClick={this.handleClickPopup} className="btn btn-blue">Запросить расчет
                                </button>
                            </div>
                        </div>
                        <img src={phone1} srcSet={`${phone1} 1x, ${phone2} 2x`} alt="Изображение телефона"/>
                    </div>
                </div>
                <div className="home-block-helpful">
                    <div className="container">
                        <h2>Кому будет полезно?</h2>
                        <div className="btn-group">
                            <button className={this.state.block1 ? ("active") : null}
                                    onClick={(e) => this.handleClick(1, e)}><Skyscrapper/>Управляющим
                                компаниям
                            </button>
                            <button className={this.state.block2 ? ("active") : null}
                                    onClick={(e) => this.handleClick(2, e)}><Faucet/>Ресурсным организациям
                            </button>
                            <button className={this.state.block3 ? ("active") : null}
                                    onClick={(e) => this.handleClick(3, e)}><Factory/>Промышленным
                                предприятиям
                            </button>
                        </div>
                        <div
                            className={this.state.block1 && "collapse  left" || this.state.block2 && "collapse  center" || "collapse right"}>
                            {this.state.block1 &&
                            <div className="text">
                                <b>Наши системы телеметрии позволяют производить удалённые измерения и контроль в
                                    системах водоснабжения, теплоснабжения, учета электроэнергии и газоснабжения. То
                                    есть показания счетчиков не нужно снимать вручную- всё происходит дистанционно и
                                    автоматически. </b>
                                <br/>
                                <span>
                                    Мы предлагаем готовую, хорошо масштабируемую систему измерений. Её можно
                                    использовать как для учёта нескольких счётчиков в одной квартире, так и для
                                    мониторинга показателей расхода целого микрорайона и даже города. Кроме простого
                                    отслеживания текущих значений использования ресурсов, средства телеметрии дают
                                    возможность своевременно выявлять утечки, удалённо управлять оборудованием,
                                    формировать отчеты по расходованию ресурсов и экономии.
                                </span>
                            </div>

                            }
                            {this.state.block2 &&
                            <div className="text">
                                <b>Подавляющее число ресурсоснабжающих организаций сталкиваются с проблемой отстутствия
                                    контроля потребления воды на узлах, бухгалтерия тратит колоссальное количество
                                    времени
                                    на сбор и обработку показаний и выставление счетов. </b>
                                <br/>
                                <span>
                                    Ресурсная организация несёт большие расходы на обходной персонал, а так же за счёт
                                    разницы полученной воды и потребляемой возникают потери при доставке ресурсов до
                                    потребителя. Решение «Проттего Телеметрия»  позволит контролировать точные данные о
                                    расходе потребляемых энергоресурсов и сократить расходы на персонал.
                                </span>
                            </div>
                            }
                            {this.state.block3 &&
                            <div className="text">
                                <b>Система автономного учёта данных в коммунально-промышленном секторе, предлагаемая
                                    компанией «Проттего», рассчитана на широкий круг потребителей измерительных систем и
                                    приборов учёта с целью:
                                </b>
                                <br/>
                                <ul>
                                    <li>учёта потребления и формирования отчётов;</li>
                                    <li>диагностики и настройки оборудования;</li>
                                    <li>мониторинга технологических параметров;</li>
                                    <li>уведомления о нештатных ситуациях.</li>
                                </ul>
                            </div>
                            }
                            <div className="image">
                                <img src={counter1} srcSet={`${counter1} 1x, ${counter2} 2x`}
                                     alt="Изображение счетчика"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home-block-about">
                    <div className="container">
                        <div className="image">
                            <img src={monitor1} srcSet={`${monitor1} 1x, ${monitor2} 2x`} alt="Контролируйте точные данные о расходе потребляемых энергоресурсов с удобного для Вас устройства"/>
                        </div>
                        <div className="text">
                            <h2>Облачная платформа “Проттего Телеметрия”</h2>
                            <p>
                                Отложите плановые обходы в подвалы для снятия данных со счётчиков!
                                Контролируйте точные данные о расходе потребляемых энергоресурсов с удобного для Вас
                                устройства Выявляйте несанкционированное использование ресурсов. Снижайте показатель
                                общедомовых нужд путём внедрения решения для предотвращения потерь. <b>Оптимизируйте
                                расходы по оплате труда, при этом повысив эффективность деятельности своей управляющей
                                компании.</b>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="home-block-and">
                    <div className="container">
                        <h2>А так же мы</h2>
                        <div className="block-group">
                            <IconTextBlock icon={<ToolsIcon/>} title="Внедряем"
                                           text="необходимый вам функционал в систему"/>
                            <IconTextBlock icon={<DeepIcon/>} title="Поверяем и обслуживаем"
                                           text="теплоузлы и приборы учета данных"/>
                            <IconTextBlock icon={<CloudIcon/>} title="Интегрируем"
                                           text="обмен данными с другими ПО"/>
                        </div>
                        <ArrowBlock/>
                    </div>

                </div>
                <div className="home-block-presentation">
                    <div className="container">
                        <h2>Скачайте нашу презентацию</h2>
                        <img src={presentation2} alt="Изображение презентации"/>
                        <a className="btn btn-primary" href="/assets/doc/Prottego_telemetry.pdf" download>Скачать
                            презентацию </a>
                    </div>
                </div>
                {this.state.showPopup ?
                    <Popup closePopup={this.handleClickPopup}/>
                    : null
                }
            </PageTemplate>
        )

    }
}

export default Home;

