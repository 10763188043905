import React, {Component} from 'react';
import '../assets/scss/app.scss';
import Home from "./pages/home";
import Solution from "./pages/solution";
import Technology from "./pages/technology";
import About from "./pages/about";
import NewsList from "./pages/news-list";
import Contacts from "./pages/contacts";
import NotFound from "./pages/404";
import News from "./pages/news";


import {BrowserRouter, Route, Switch} from 'react-router-dom';


class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/solution" component={Solution}/>
                    <Route path="/technology" component={Technology}/>
                    <Route path="/about" component={About}/>
                    <Route exact path="/news" component={NewsList}/>
                    <Route path="/news/:id" component={News} />
                    <Route path="/contacts" component={Contacts}/>
                    <Route component={NotFound}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;