import React, {Component} from 'react';
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import fetch from "isomorphic-fetch";

const phoneRegExp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/

const FeedbackSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Слишком короткое имя')
        .max(50, 'Слишком длинное имя')
        .required('Обязательное поле'),
    email: Yup.string()
        .email('Некорректный емаил')
        .required('Обязательное поле'),
    phone: Yup.string()
        .matches(phoneRegExp, 'Некорректный номер телефона')
        .required('Обязательнное поле')
})

class FeedbackForm extends Component {
    constructor() {
        super();
        this.state = {
            error_api: false
        }
    }
    render(){
        return(
            <div>
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        phone: ''
                    }}
                    onSubmit={values => {
                        fetch(`${process.env.API_URL}/api/requests_user/`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json, text/plain',
                                'Content-Type': 'application/json;charset=UTF-8'
                            },
                            body: JSON.stringify(values)
                        })
                            .then(response => this.props.SubmitForm())
                            .catch(response => this.setState({error_api: true}))

                    }}
                    validationSchema={FeedbackSchema}
                >
                    {({errors, touched}) => (
                        <Form className="horizontal">
                            <div className={`group ${errors.name && touched.name ? ("error") : ''}`}>
                                <Field type="text" id="name" name="name" required/>
                                <label>Ваше имя</label>
                                {errors.name && touched.name ? (<div>{errors.name}</div>) : null}
                            </div>
                            <div className={`group ${errors.email && touched.email ? ("error") : ''}`}>
                                <Field id="email" name="email" required/>
                                <label>Почта</label>
                                {errors.email && touched.email ? (<div>{errors.email}</div>) : null}
                            </div>
                            <div className={`group ${errors.phone && touched.phone ? ("error") : ''}`}>
                                <Field tid="phone" name="phone" required/>
                                <label>Телефон</label>
                                {errors.phone && touched.phone ? (<div>{errors.phone}</div>) : null}
                            </div>
                            <button className="btn btn-white" type="submit">Запросить расчет</button>
                        </Form>
                    )}
                </Formik>
                <div className="error">{this.state.error_api ? "Ошибка связи с сервером. Повторите попытку позже." : null }</div>
            </div>

        )
    }
}


export default FeedbackForm;