import {NavLink} from 'react-router-dom'
import React from 'react';


const MainMenu = () =>
    <nav className="main-menu">
        <NavLink exact to="/">
            Главная
        </NavLink>
        <NavLink to="/solution">
            Решения
        </NavLink>
        <NavLink to="/technology">
            Технологии
        </NavLink>
        <NavLink to="/about">
            О компании
        </NavLink>
        <NavLink to="/news">
            Новости
        </NavLink>
        <NavLink to="/contacts">
            Контакты
        </NavLink>
    </nav>
export default MainMenu;