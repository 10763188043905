import React from 'react';

const ArrowBlock = () =>
    <div className="block-arrow">
        <div>
            <i className="arrow one"/>
        </div>
        <div>
            <i className="arrow two"/>
        </div>
        <div>
            <i className="arrow three"/>
        </div>
    </div>

export default ArrowBlock