import React from 'react';
import PropTypes from 'prop-types';


const ConfidenceBlock = (props) =>
    <div className='confidence-block'>
        {props.icon}
        <span dangerouslySetInnerHTML={{__html: props.text}}/>
    </div>

ConfidenceBlock.propTypes = {
    icon: PropTypes.element.isRequired,
    text: PropTypes.string.isRequired,
}

export default ConfidenceBlock;