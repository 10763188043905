import React from 'react';
import PageTemplate from "./base/base";
import ContactMap from "../components/map";
import {ClockIcon, EmailIcon, PhoneIcon, PinIcon} from "../components/icons/icons";
import {Helmet} from "react-helmet";

const Contacts = () => {
    return (
        <PageTemplate>
            <Helmet>
                <title>Контакты | Prottego Телеметрия</title>
                <meta name="description" content='Специалисты "Проттего Телеметрия" готовы предоставить Вам дополнительную информацию,
                ответить на Ваши вопросы и создать эффективное решение под Ваши нужды' />
            </Helmet>
            <div className="contacts-block-main">
                <div className="container">
                    <h2>Наши контакты</h2>
                    <p>Специалисты "Проттего" готовы предоставить Вам дополнительную информацию, ответить на Ваши
                        вопросы и
                        создать эффективное решение под Ваши нужды.</p>
                </div>

            </div>
            <ContactMap/>
            <div className="contacts-block-contact">
                <div className="container" itemScope itemType="http://schema.org/Organization">
                    <div className="block requisites">
                        <h4 itemProp="name">ООО "Безопасная школа"</h4>
                        <ul>
                            <li>ИНН: 0257011318</li>
                            <li>КПП: 025701001</li>
                            <li>ОГРН: ОГРН</li>
                            <li>
                                <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                                    <PinIcon/><span itemProp="postalCode">452452</span>, Республика Башкортостан,
                                    г. <span itemProp="addressLocality">Бирск</span>,
                                    <span itemProp="streetAddress"> ул. Коммунистическая, 97В</span>
                                </div>

                            </li>

                        </ul>
                    </div>
                    <div className="block contacts">
                        <h4>Как с нами связаться?</h4>
                        <ul>
                            <li><PhoneIcon/><a href="tel:+73472008283"><span
                                itemProp="telephone">+7 (347) 200 82 83</span></a></li>
                            <li><EmailIcon/><a href="mailto:support@prottego.ru"><span
                                itemProp="email">support@prottego.ru</span></a></li>
                            <li><PinIcon/>ул. Коммунистическая, 97В</li>
                            <li><ClockIcon/>Пн-Пт: 9:00-18:00</li>
                        </ul>
                    </div>
                </div>
            </div>
        </PageTemplate>
    )
}

export default Contacts