import React, {Component} from 'react';
import PageTemplate from "./base/base";
import NewsBlock from "../components/block/news-block";
import fetch from 'isomorphic-fetch'
import {Helmet} from "react-helmet";

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: []
        }
    }

    componentDidMount() {
        fetch(`${process.env.API_URL}/api/completed_project/`)
            .then(response => response.json())
            .then(projects => this.setState({projects}))
    }

    render() {
        const {projects} = this.state
        return (
            <PageTemplate>
                <Helmet>
                    <title>О компании | Prottego Телеметрия</title>
                    <meta name="description" content="«Проттего Телеметрия» - российская IT-компания, основанная в 2016 году, в
                    городе Уфа. За этот период компания накопила значительный опыт в реализации проектов в области ЖКХ" />
                </Helmet>
                <div className="about-block-main">
                    <div className="container">
                        <h2>Кто мы?</h2>
                            <p>
                                «Проттего» - российская IT-компания, основанная в 2016 году, в городе Уфа. За этот
                                период
                                компания накопила значительный опыт в реализации проектов в области Интернета-Вещей.
                                «Проттего» является разработчиком программного обеспечения «Проттего Телеметрия»,
                                интегратором беспроводных технологий в приборы учёта ЖКХ на базе современных LoraWAN
                                технологий.
                            </p>
                            <p>Компания «Проттего» работает для наших заказчиков и вместе с ними, предлагая наиболее
                                простые
                                решения для автоматизации бизнес-процессов. Для нахождения этих решений, мы тщательно
                                изучаем и мониторим новейшие разработки и тенденции в сфере интернета-вещей, уделяем
                                внимание на потребности наших заказчиков.
                            </p>
                            <p>
                                «Проттего» всегда рядом и ориентирована на успех клиента. Ваш успех – наша рекомендация
                                на
                                рынке.
                            </p>
                    </div>
                </div>
                <div className="about-block-project">
                    <div className="container">
                        <h2>Реализованные проекты</h2>
                        <div className="news-block-group">
                            {projects.length ? projects.map(
                                (item) => <NewsBlock key={item.id.toString()} image={item.image} title={item.title}
                                                     short_text={item.short_text} id={item.id}/>)
                                : <div>Данный раздел находится в разработке</div>
                            }
                        </div>
                        <div className="clear"/>
                    </div>
                </div>
            </PageTemplate>
        )
    }
}


export default About;