import React from 'react';
import PropTypes from 'prop-types';


function OpportunitiesBlock(props) {
    const image = window.devicePixelRatio > 1 ? props.image2 : props.image1
    return (
        <div className='opportunities-block' style={{backgroundImage: `url(${image})`, backgroundSize: "cover"}}>
            <div>
                {props.title}
            </div>
        </div>
    )
}

OpportunitiesBlock.propTypes = {
    image1: PropTypes.string.isRequired,
    image2: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,

}

export default OpportunitiesBlock;